import React, { useState, useEffect, useRef } from "react";
import { motion, useMotionValue } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, Grid } from "swiper/modules";
import {
  BookOpenIcon,
  DevicePhoneMobileIcon,
  ChatBubbleBottomCenterTextIcon,
  SparklesIcon,
  UserGroupIcon,
  BookmarkIcon,
  StarIcon,
  AcademicCapIcon,
  EyeIcon,
  GlobeAltIcon,
  BookmarkSquareIcon,
  RectangleStackIcon,
  LightBulbIcon,
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { StarIcon as SolidStarIcon } from "@heroicons/react/24/solid";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/grid";
import "./App.css";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

// --- Mock Data ---

const appScreenshots = [
  {
    image: "/screenshots/reading-space.png",
    title: "Dynamic Reading",
    description:
      "Immerse yourself in beautifully designed reading environments, personalise it completely and enjoy a gamified reading experience.",
  },
  {
    image: "/screenshots/insights.png",
    title: "Smart Insights",
    description:
      "Need some context? Maybe wondering how a character fits into the story, or wanting to learn about quantum mechanics? Ask your companion and get instant answers.",
  },
  {
    image: "/screenshots/discussions.png",
    title: "Live Discussions",
    description:
      "When you buy a book, you instantly join a global community of readers. Engage in meaningful conversations about your favorite books.",
  },
];

const testimonials = [
  {
    text: "The daily reading streak reminders are a great touch—although sometimes they arrive a bit too early in the day for me.",
    author: "Alex P.",
    role: "Literature Student",
    stars: 5,
  },
  {
    text: "I joined because a friend recommended it. Still figuring out all the features, but so far I like the clean layout.",
    author: "Stefan B.",
    role: "Book Club Leader",
    stars: 5,
  },
  {
    text: "Love the interactive feel of the app, but it would be great if offline reading was available. I heard it's in the works though!",
    author: "Emily R.",
    role: "Avid Reader",
    stars: 5,
  },
  {
    text: "There's something about this app that keeps me hooked. I love the interactive reading experience and how you can track progress over time. Makes a difference for people like me who want to read more.",
    author: "Tavleen K.",
    role: "Book Enthusiast",
    stars: 5,
  },
  {
    text: "Sharing thoughts on specific sections of the book is a great feature. Feels like I'm part of a book club without any of the pressure. Would love more personalization options in future updates.",
    author: "Matsya B.",
    role: "Book Enthusiast",
    stars: 5,
  },
  {
    text: "Was skeptical at first, but the Companion responses actually add a lot. Makes reading more interactive. Definitely worth a try.",
    author: "Stacie W.",
    role: "Book Enthusiast",
    stars: 5,
  },
  {
    text: "Honestly, Bookt made me enjoy reading again. Didn't think I'd like reading on an app, but it's surprisingly fun.",
    author: "Elena P.",
    role: "Book Enthusiast",
    stars: 5,
  },
  {
    text: "I appreciate how quick the team is with updates. They actually listen to feedback, which is rare with apps these days.",
    author: "Jan M.",
    role: "Book Enthusiast",
    stars: 5,
  },
];

// --- FloatingIcon Component ---

// const FloatingIcon = ({
//   Icon,
//   initialX,
//   initialY,
//   containerWidth,
//   containerHeight,
// }) => {
//   const x = useMotionValue(initialX);
//   const y = useMotionValue(initialY);
//   const iconRef = useRef(null);

//   useEffect(() => {
//     const speed = 0.3;
//     const angle = Math.random() * Math.PI * 2;
//     const velocity = {
//       x: Math.cos(angle) * speed,
//       y: Math.sin(angle) * speed,
//     };

//     const interval = setInterval(() => {
//       let newX = x.get() + velocity.x;
//       let newY = y.get() + velocity.y;

//       // Use container dimensions (fallback to window dimensions if not available)
//       const maxWidth = containerWidth || window.innerWidth;
//       const maxHeight = containerHeight || window.innerHeight;
//       // Subtract icon size (48px) for wrap-around logic
//       const boundaryX = maxWidth - 48;
//       const boundaryY = maxHeight - 48;

//       // Collision detection (simple distance-based bounce)
//       if (iconRef.current) {
//         const currentRect = iconRef.current.getBoundingClientRect();
//         const currentCenter = {
//           x: currentRect.left + currentRect.width / 2,
//           y: currentRect.top + currentRect.height / 2 + window.scrollY,
//         };

//         const otherIcons = document.querySelectorAll(".floating-icon");
//         otherIcons.forEach((other) => {
//           if (other === iconRef.current) return;
//           const otherRect = other.getBoundingClientRect();
//           const otherCenter = {
//             x: otherRect.left + otherRect.width / 2,
//             y: otherRect.top + otherRect.height / 2 + window.scrollY,
//           };

//           const dx = currentCenter.x - otherCenter.x;
//           const dy = currentCenter.y - otherCenter.y;
//           const distance = Math.sqrt(dx * dx + dy * dy);

//           if (distance < 50) {
//             // Collision threshold
//             const collisionAngle = Math.atan2(dy, dx);
//             velocity.x = Math.cos(collisionAngle) * speed;
//             velocity.y = Math.sin(collisionAngle) * speed;
//           }
//         });
//       }

//       // Wrap around the container edges
//       if (newX < 0) newX = boundaryX;
//       if (newX > boundaryX) newX = 0;
//       if (newY < 0) newY = boundaryY;
//       if (newY > boundaryY) newY = 0;

//       x.set(newX);
//       y.set(newY);
//     }, 16);

//     return () => clearInterval(interval);
//   }, [containerWidth, containerHeight, x, y]);

//   return (
//     <motion.div ref={iconRef} className="floating-icon" style={{ x, y }}>
//       <Icon />
//     </motion.div>
//   );
// };

// --- FloatingIcons Component ---

// const FloatingIcons = () => {
//   const [positions, setPositions] = useState([]);
//   const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
//   const containerRef = useRef(null);

//   useEffect(() => {
//     const updatePositions = () => {
//       if (!containerRef.current) return;
//       const rect = containerRef.current.getBoundingClientRect();
//       const width = rect.width;
//       const height = rect.height;
//       const newPositions = Array.from({ length: 40 }, () => ({
//         x: Math.random() * (width - 100), // using a 100px offset as in the original code
//         y: Math.random() * height,
//       }));
//       setPositions(newPositions);
//       setContainerSize({ width, height });
//     };

//     updatePositions();
//     window.addEventListener("resize", updatePositions);

//     return () => {
//       window.removeEventListener("resize", updatePositions);
//     };
//   }, []);

//   const icons = [
//     BookOpenIcon,
//     RectangleStackIcon,
//     SparklesIcon,
//     EyeIcon,
//     ChatBubbleBottomCenterTextIcon,
//     BookmarkIcon,
//     GlobeAltIcon,
//     AcademicCapIcon,
//     LightBulbIcon,
//     BookmarkSquareIcon,
//   ];

//   return (
//     <div ref={containerRef} className="floating-icons-container">
//       {positions.map((pos, index) => (
//         <FloatingIcon
//           key={`icon-${index}`}
//           Icon={icons[index % icons.length]}
//           initialX={pos.x}
//           initialY={pos.y}
//           containerWidth={containerSize.width}
//           containerHeight={containerSize.height}
//         />
//       ))}
//     </div>
//   );
// };

// --- Main App Component ---

function App() {
  const location = useLocation();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isMobile, setIsMobile] = useState(
    typeof window !== "undefined" && window.innerWidth <= 1024
  );
  const [expandedFaq, setExpandedFaq] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY,
      });
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="app">
      <Helmet>
        <title>
          BOOKT | The Future of Reading: Immersive, Social & AI-Powered
        </title>
        <meta
          name="description"
          content="Experience books like never before with Bookt. AI-powered insights, real-time discussions, and a personalized reading experience designed for the modern reader. Learn, explore, and connect with a global reading community."
        />
        <link rel="canonical" href="https://bookt.app" />
        <meta
          property="og:title"
          content="BOOKT | The Future of Reading: Immersive, Social & AI-Powered"
        />
        <meta
          property="og:description"
          content="Experience immersive, social, AI-powered reading."
        />
        <meta property="og:image" content="/images/social-preview.webp" />
        <meta property="og:url" content="https://bookt.app" />
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "Bookt",
            "url": "https://bookt.app"
          }
        `}</script>
      </Helmet>
      <nav className={`navbar ${isMobileMenuOpen ? "menu-open" : ""}`}>
        <a href="https://bookt.app">
          <img
            className="logo"
            src="/images/bookt-logo-header.webp"
            alt="Bookt"
          />
        </a>
        <div className="desktop-nav">
          <a
            href={location.pathname === "/ai" ? "/" : "https://booktai.app"}
            className={`nav-cta ${
              location.pathname === "/ai" ? "" : "ai-button"
            }`}
          >
            {location.pathname === "/ai" ? "Personal" : "Work with AI"}
          </a>
          <a href="https://my.bookt.app/auth/login" className="nav-cta button">
            Login
          </a>
        </div>
        <button
          className="mobile-menu-button"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          {isMobileMenuOpen ? (
            <XMarkIcon className="h-6 w-6" />
          ) : (
            <Bars3Icon className="h-6 w-6" />
          )}
        </button>
        {isMobileMenuOpen && (
          <div className="mobile-menu open">
            <a
              href={location.pathname === "/ai" ? "/" : "https://booktai.app"}
              className={`nav-cta ${
                location.pathname === "/ai" ? "" : "ai-button"
              }`}
              onClick={() => setIsMobileMenuOpen(false)}
            >
              {location.pathname === "/ai" ? "Personal" : "Work with AI"}
            </a>
            <a
              href="https://my.bookt.app/auth/login"
              className="nav-cta button"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Login
            </a>
          </div>
        )}
      </nav>
      <main>
        {/* <div className="floating-icons-div">
          <FloatingIcons />
        </div> */}
        {/* Hero Section */}
        <section className="hero">
          <div className="hero-container">
            <div className="hero-content">
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="hero-title seo-h1"
              >
                Reading, Reimagined:
                <br />A Smarter, More Connected Experience
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                className="hero-subtitle"
              >
                Discover dynamic reading spaces, unlock deeper insights, and
                join global book discussions effortlessly.
              </motion.p>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
                className="hero-cta"
              >
                <a
                  href="https://my.bookt.app/auth/signup"
                  className="btn-primary"
                >
                  Start Reading for Free
                </a>
                <div className="app-store-buttons">
                  <a
                    href="https://apps.apple.com/us/app/bookt/id6451315434"
                    className="store-button app-store"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/images/app-store/app-store.png"
                      alt="Download on the App Store"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=app.bookt.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    className="store-button play-store"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/images/app-store/play-store.png"
                      alt="Get it on Google Play"
                    />
                  </a>
                </div>
              </motion.div>
            </div>
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, delay: 0.6 }}
              className="hero-phone"
            >
              <img src="/images/bookt-hero-image.webp" alt="BOOKT App Demo" />
            </motion.div>
          </div>
        </section>

        {/* Beta Program Section */}
        <section className="beta-program">
          <div className="beta-container">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              Join the Beta Program
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.1 }}
              className="beta-subheading"
            >
              As seen on our socials
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSeRoYeQfNK7H1RscfpQA2whw60jBIWxYg2jOw-yU-lbYNPK7Q/viewform?usp=header"
                className="btn-primary beta-button"
                target="_blank"
                rel="noreferrer"
              >
                Join Now
              </a>
            </motion.div>
          </div>
        </section>

        {/* Features Section */}
        <section className="features">
          <h2>A New Way to Experience Books</h2>
          <p className="subtitle">
            Transform your reading journey with interactive features and
            personalized insights
          </p>
          <div className="features-grid">
            <motion.div
              className="feature-card"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <BookOpenIcon className="feature-icon" />
              <h3>Deeply Engaging</h3>
              <p>
                Customize your experience, track progress, and unlock
                achievements in a seamless, distraction-free reading
                environment.
              </p>
            </motion.div>

            <motion.div
              className="feature-card"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <DevicePhoneMobileIcon className="feature-icon" />
              <h3>Unlock Deeper Insights</h3>
              <p>
                Get instant context, visualize characters, and ask questions for
                real-time insights—making every book smarter and more engaging.
              </p>
            </motion.div>

            <motion.div
              className="feature-card"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <ChatBubbleBottomCenterTextIcon className="feature-icon" />
              <h3>Read with the World</h3>
              <p>
                Join live discussions, see global insights, and connect with
                readers experiencing the same book in real time.
              </p>
            </motion.div>
          </div>
        </section>

        {/* How It Works Section */}
        <section className="how-it-works">
          <h2>How Bookt Works</h2>
          <p className="subtitle">
            Discover the innovative features that make reading more engaging and
            interactive
          </p>
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={30}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            autoplay={{ delay: 3000 }}
            className="app-screenshots-carousel"
          >
            {appScreenshots.map((screenshot, index) => (
              <SwiperSlide key={index}>
                <div className="screenshot-content">
                  <h3>{screenshot.title}</h3>
                  <p>{screenshot.description}</p>
                </div>
                <div className="screenshot-image">
                  <img src={screenshot.image} alt={screenshot.title} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </section>

        {/* Mastery Section */}
        {/* <section className="features mastery-preview">
          <h2>Level Up with Mastery</h2>
          <p className="subtitle">
            Bookt Premium includes Mastery, your gateway to AI-powered learning
            and self-development.
          </p>
          <div className="features-grid">
            <motion.div
              className="feature-card"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <AcademicCapIcon className="feature-icon" />
              <h3>What's Mastery</h3>
              <p>
                An AI-driven experience that turns books, articles, and videos
                into bite-sized insights for real-world application.
              </p>
            </motion.div>
            <motion.div
              className="feature-card"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <UserGroupIcon className="feature-icon" />
              <h3>For Teams</h3>
              <p>
                Empower your workforce with daily AI-enhanced microlearning that
                seamlessly integrates into their workflow and drives growth.
              </p>
            </motion.div>

            <motion.div
              className="feature-card"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <SparklesIcon className="feature-icon" />
              <h3>For Self-Improvement</h3>
              <p>
                Gain the essential insights and skills to thrive in an
                AI-powered world and secure your competitive edge.
              </p>
            </motion.div>
          </div>
          <motion.div
            className="mastery-cta"
            whileHover={{ scale: 1.02 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <a href="/mastery" className="btn-primary">
              Explore Mastery
            </a>
          </motion.div>
        </section> */}

        {/* Testimonials Section */}
        <section className="testimonials">
          <h2>What Readers Are Saying</h2>
          <p className="subtitle">
            Join thousands of satisfied readers who have transformed their
            reading experience
          </p>
          <div className="testimonials-desktop">
            <div className="testimonials-grid">
              {testimonials.slice(0, 8).map((testimonial, index) => (
                <motion.div
                  key={index}
                  className="testimonial-card"
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <div className="testimonial-stars">
                    {[...Array(testimonial.stars)].map((_, i) => (
                      <SolidStarIcon key={i} className="h-5 w-5" />
                    ))}
                  </div>
                  <p className="testimonial-text">{testimonial.text}</p>
                  <div className="testimonial-author">
                    <h4>{testimonial.author}</h4>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
          {isMobile && (
            <div className="testimonials-mobile">
              <Swiper
                modules={[Navigation, Autoplay, Grid]}
                spaceBetween={24}
                slidesPerView={1}
                // grid={{
                //   rows: 2,
                //   fill: "row",
                // }}
                navigation
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                loop={false}
                className="testimonials-carousel"
              >
                {testimonials.slice(0, 8).map((testimonial, index) => (
                  <SwiperSlide key={index}>
                    <motion.div
                      className="testimonial-card"
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: "spring", stiffness: 300 }}
                    >
                      <div className="testimonial-stars">
                        {[...Array(testimonial.stars)].map((_, i) => (
                          <SolidStarIcon key={i} className="h-5 w-5" />
                        ))}
                      </div>
                      <p className="testimonial-text">{testimonial.text}</p>
                      <div className="testimonial-author">
                        <h4>{testimonial.author}</h4>
                      </div>
                    </motion.div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </section>

        {/* CTA Section */}
        <section className="cta-section">
          <div className="cta-section-div">
            <div className="cta-content">
              <h2 className="cta-title">
                Join readers worldwide and explore immersive books.
              </h2>
              <div className="app-store-buttons">
                <a
                  href="https://apps.apple.com/us/app/bookt/id6451315434"
                  className="store-button app-store"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/images/app-store/app-store.png"
                    alt="Download on the App Store"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=app.bookt.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  className="store-button play-store"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/images/app-store/play-store.png"
                    alt="Get it on Google Play"
                  />
                </a>
              </div>
            </div>
            <div className="cta-image">
              <img src="/images/app-preview.webp" alt="BOOKT App Preview" />
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section className="faq-section">
          <h2>Questions?</h2>
          <div className="faq-container">
            <motion.div className="faq-item">
              <motion.button
                className="faq-question"
                onClick={() => setExpandedFaq(expandedFaq === 0 ? null : 0)}
                initial={false}
              >
                <span>Do you use AI, if so why and how?</span>
                <motion.div
                  className="faq-icon"
                  animate={{ rotate: expandedFaq === 0 ? 180 : 0 }}
                >
                  <ChevronDownIcon className="h-5 w-5" />
                </motion.div>
              </motion.button>
              <motion.div
                className="faq-answer"
                initial={false}
                animate={{
                  height: expandedFaq === 0 ? "auto" : 0,
                  opacity: expandedFaq === 0 ? 1 : 0,
                }}
                transition={{ duration: 0.3 }}
              >
                <p>
                  Yes, we've carefully integrated deep learning to make reading
                  more engaging and immersive. With Bookt, you'll have a
                  personal reading companion by your side, offering helpful
                  context, definitions, and insights as you read—just like a
                  knowledgeable friend who's always there to help.
                </p>
                <p>
                  What sets us apart is our commitment to sustainability. Unlike
                  platforms that rely on massive, energy-hungry systems, our
                  technology is self-hosted and runs efficiently on a single
                  server—using about the same energy as a good desktop computer.
                  You get an enhanced reading experience without the
                  environmental footprint.
                </p>
              </motion.div>
            </motion.div>

            <motion.div className="faq-item">
              <motion.button
                className="faq-question"
                onClick={() => setExpandedFaq(expandedFaq === 1 ? null : 1)}
                initial={false}
              >
                <span>What about data privacy and an author's copyright?</span>
                <motion.div
                  className="faq-icon"
                  animate={{ rotate: expandedFaq === 1 ? 180 : 0 }}
                >
                  <ChevronDownIcon className="h-5 w-5" />
                </motion.div>
              </motion.button>
              <motion.div
                className="faq-answer"
                initial={false}
                animate={{
                  height: expandedFaq === 1 ? "auto" : 0,
                  opacity: expandedFaq === 1 ? 1 : 0,
                }}
                transition={{ duration: 0.3 }}
              >
                <p>
                  At Bookt, we prioritize your privacy by using the highest
                  level of encryption, ensuring that most of your data isn't
                  even visible to us. We are committed to transparency and
                  respect—we never train on your data, nor do we share it with
                  any third parties. For authors, we pledge to safeguard their
                  creative work.
                </p>
                <p>
                  Our AI models are never trained on the contents of their
                  books, nor do we allow our models direct access to their work.
                  We collaborate closely with publishers and authors, ensuring
                  they remain the primary beneficiaries of any value created
                  through emerging technology while protecting their
                  intellectual property at every step.
                </p>
              </motion.div>
            </motion.div>

            <motion.div className="faq-item">
              <motion.button
                className="faq-question"
                onClick={() => setExpandedFaq(expandedFaq === 2 ? null : 2)}
                initial={false}
              >
                <span>What types of books are available on Bookt?</span>
                <motion.div
                  className="faq-icon"
                  animate={{ rotate: expandedFaq === 2 ? 180 : 0 }}
                >
                  <ChevronDownIcon className="h-5 w-5" />
                </motion.div>
              </motion.button>
              <motion.div
                className="faq-answer"
                initial={false}
                animate={{
                  height: expandedFaq === 2 ? "auto" : 0,
                  opacity: expandedFaq === 2 ? 1 : 0,
                }}
                transition={{ duration: 0.3 }}
              >
                <p>
                  Bookt offers a wide selection of both fiction and non-fiction
                  books, covering everything from heartwarming romance to
                  thought-provoking non-fiction. We work with many of the
                  world's major publishers, ensuring that most of your favorite
                  titles are available. Our library is constantly expanding, so
                  whether you're diving into a thrilling fantasy, exploring a
                  gripping mystery, or seeking personal growth through
                  non-fiction, there's always something new to discover.
                </p>
                <p>
                  Bookt is currently available in the US, Africa, South America,
                  and most Asian countries, making it easy for readers across
                  the globe to join vibrant reading communities and enjoy
                  immersive stories.
                </p>
              </motion.div>
            </motion.div>
          </div>
        </section>

        <footer className="footer">
          <div className="footer-content">
            <h2>Ignite your curiosity</h2>
            <a href="https://my.bookt.app/auth/login" className="footer-cta">
              SIGN IN
            </a>
            <div className="social-links">
              <a
                href="https://x.com/lnkorsten"
                className="social-link"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                </svg>
              </a>
              <a
                href="https://www.youtube.com/@bookt_app"
                className="social-link"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0C.488 3.45.029 5.804 0 12c.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0C23.512 20.55 23.971 18.196 24 12c-.029-6.185-.484-8.549-4.385-8.816zM9 16V8l8 3.993L9 16z" />
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/in/louis-neil/"
                className="social-link"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                </svg>
              </a>
            </div>
            <div className="footer-logo">
              <a href="https://bookt.app">
                <img src="/images/bookt-logo.png" alt="Bookt" />
              </a>
            </div>
            <div className="footer-links">
              <a href="https://my.bookt.app/privacy">Privacy Policy</a>
              <a href="https://my.bookt.app/terms">Terms & Conditions</a>
            </div>
            <p className="copyright">&copy; 2025 — BOOKT</p>
          </div>
        </footer>
      </main>
    </div>
  );
}

export default App;
