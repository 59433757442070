import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Mastery from "./Mastery";
import AI from "./AI";
import { HelmetProvider } from "react-helmet-async";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import ReactGA from "react-ga4";

// Initialize GA4
ReactGA.initialize("G-9SZV80YNTZ"); // Replace with your measurement ID

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/mastery" element={<Mastery />} />
          <Route path="/ai" element={<AI />} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
    <Analytics />
    <SpeedInsights />
  </React.StrictMode>
);
