import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Helmet } from "react-helmet-async";
import {
  LightBulbIcon,
  ChatBubbleBottomCenterTextIcon,
  PuzzlePieceIcon,
  ShieldCheckIcon,
  DocumentTextIcon,
  PhotoIcon,
  ArrowPathIcon,
  GlobeAltIcon,
  ChartBarIcon,
  UserGroupIcon,
  DocumentPlusIcon,
  RocketLaunchIcon,
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import { StarIcon as SolidStarIcon } from "@heroicons/react/24/solid";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./App.css";
import "./AI.css";

// Mock testimonials data
const testimonials = [
  {
    text: "BooktAI removed all my worries about AI complexity. It's amazingly simple.",
    author: "Alex T.",
    role: "Marketing Specialist",
    stars: 5,
  },
  {
    text: "Our team's productivity improved overnight. Highly recommend!",
    author: "Michael R.",
    role: "HR Manager",
    stars: 5,
  },
  {
    text: "Finally, powerful AI at a price we can afford, and security we trust.",
    author: "Jennifer K.",
    role: "IT Security Lead",
    stars: 5,
  },
  {
    text: "BooktAI has revolutionized how our team approaches AI. It's intuitive enough for beginners yet powerful for our tech team.",
    author: "Sarah L.",
    role: "Operations Director",
    stars: 5,
  },
];

// Main component
function AI() {
  // const location = useLocation();
  const [isMobile, setIsMobile] = useState(
    typeof window !== "undefined" && window.innerWidth <= 1024
  );
  const [expandedFaq, setExpandedFaq] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isAnnual, setIsAnnual] = useState(true);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isControlVisible, setIsControlVisible] = useState(true);
  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);
  const controlTimeoutRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleVideoPlay = () => {
    const video = videoRef.current;
    if (video) {
      if (isVideoPlaying) {
        video.pause();
      } else {
        video.muted = false;
        video.play();
      }
      setIsVideoPlaying(!isVideoPlaying);
    }
  };

  const handleMouseMove = () => {
    setIsControlVisible(true);

    if (isVideoPlaying) {
      // Clear any existing timeout
      if (controlTimeoutRef.current) {
        clearTimeout(controlTimeoutRef.current);
      }

      // Set a new timeout to hide controls after 2 seconds
      controlTimeoutRef.current = setTimeout(() => {
        setIsControlVisible(false);
      }, 2000);
    }
  };

  // Clear timeout on unmount
  useEffect(() => {
    return () => {
      if (controlTimeoutRef.current) {
        clearTimeout(controlTimeoutRef.current);
      }
    };
  }, []);

  // Hide controls when video starts playing
  useEffect(() => {
    if (isVideoPlaying) {
      const timeout = setTimeout(() => {
        setIsControlVisible(false);
      }, 2000);

      return () => clearTimeout(timeout);
    } else {
      setIsControlVisible(true);
    }
  }, [isVideoPlaying]);

  return (
    <div className="app ai">
      <Helmet>
        <title>BOOKT AI | AI Assistant - Simple, Secure & Affordable</title>
        <meta
          name="description"
          content="Powerful AI made accessible. Upload documents, generate images, collaborate with your team - all with no technical expertise required. Simple, secure, and affordable."
        />
        <link rel="canonical" href="https://bookt.app/ai" />
        <meta
          property="og:title"
          content="BOOKT AI | AI Assistant - Simple, Secure & Affordable"
        />
        <meta
          property="og:description"
          content="Powerful AI made accessible. Upload documents, generate images, collaborate with your team - all with no technical expertise required. Simple, secure, and affordable."
        />
        <meta property="og:image" content="/images/social-preview-ai.png" />
        <meta property="og:url" content="https://bookt.app/ai" />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            "name": "Bookt AI",
            "applicationCategory": "BusinessApplication",
            "offers": {
              "@type": "Offer",
              "price": "8",
              "priceCurrency": "USD"
            },
            "description": "Your all-in-one AI assistant that's simple to use, secure for your data, and affordable for everyone. Features include document processing, image generation, and team collaboration.",
            "url": "https://bookt.app/ai",
            "operatingSystem": "Web"
          }
        `}</script>
      </Helmet>

      {/* Navigation */}
      <nav className={`navbar ${isMobileMenuOpen ? "menu-open" : ""}`}>
        <a href="https://bookt.app/ai">
          <img
            className="ai-logo"
            src="/images/bookt-ai-black-logo.png"
            alt="Bookt AI"
          />
        </a>
        <div className="desktop-nav">
          <a href="/" className="nav-cta reading-button">
            Reading
          </a>
          <a href="https://my.bookt.app/auth/login" className="nav-cta button">
            Login
          </a>
        </div>
        <button
          className="mobile-menu-button"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          {isMobileMenuOpen ? (
            <XMarkIcon className="h-6 w-6" />
          ) : (
            <Bars3Icon className="h-6 w-6" />
          )}
        </button>
        {isMobileMenuOpen && (
          <div className="mobile-menu open">
            <a
              href="/"
              className="nav-cta reading-button"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Reading
            </a>
            <a
              href="https://my.bookt.app/auth/login"
              className="nav-cta button"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Login
            </a>
          </div>
        )}
      </nav>

      <main>
        {/* Hero Section */}
        <section className="hero hero-ai">
          <div className="hero-container hero-ai-container">
            <div className="hero-content">
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="hero-title seo-h1"
              >
                AI Made <span className="alt-heading-color">Simple</span>,
                Finally.
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                className="hero-subtitle"
              >
                You know you need AI but don't know where to start. We handle
                the complexity, giving you a powerful AI assistant that's
                simple, secure, and affordable.
              </motion.p>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
                className="hero-cta"
              >
                <a
                  href="#pricing"
                  className="btn-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    document.getElementById("pricing").scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                >
                  Get Started Today
                </a>
                <p className="guarantee-text">
                  7-day money-back guarantee. No questions asked.
                </p>
              </motion.div>
            </div>
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, delay: 0.6 }}
              className="hero-video"
            >
              {/* Placeholder for video */}
              <div
                className="video-container"
                ref={videoContainerRef}
                onMouseMove={handleMouseMove}
                onMouseEnter={() => setIsControlVisible(true)}
              >
                <video ref={videoRef} loop playsInLine muted={!isVideoPlaying}>
                  <source src="/videos/bookt_hero_small.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <button
                  className={`video-play-button ${
                    isVideoPlaying && !isControlVisible ? "hidden" : ""
                  }`}
                  onClick={toggleVideoPlay}
                >
                  {isVideoPlaying ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="48"
                      height="48"
                    >
                      <path d="M6 4h4v16H6V4zm8 0h4v16h-4V4z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="48"
                      height="48"
                    >
                      <path d="M8 5v14l11-7z" />
                    </svg>
                  )}
                </button>
              </div>
            </motion.div>
          </div>
        </section>

        {/* AI Models Section */}
        <section className="ai-models">
          <div className="ai-models-container">
            <h2>
              The <span className="alt-heading-color">Best AI. Always.</span> No
              Guesswork.
            </h2>
            <p className="subtitle">
              We handle the complexity. BooktAI always gives you the best model
              for every task.
            </p>
            <div className="models-image">
              <img
                src="/images/bookt_overall_image.png"
                alt="BooktAI combines the best AI models into one platform"
              />
            </div>
          </div>
        </section>

        {/* Value Proposition Section */}
        <section className="value-proposition">
          <h2>
            Our Promise:{" "}
            <span className="alt-heading-color">
              Simple. Secure. Affordable.
            </span>
          </h2>
          <p className="subtitle">
            Three core values that guide everything we do.
          </p>

          <div className="value-pillars">
            <motion.div
              className="pillar-card"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <PuzzlePieceIcon className="pillar-icon" />
              <h3 className="pillar-title">Simple</h3>
              <p className="pillar-subtitle alt-heading-color">Plug & Play</p>
              <p>
                AI doesn't have to be complicated. We give you powerful tools
                with an intuitive, simple interface, no expertise or setup
                required.
              </p>
            </motion.div>

            <motion.div
              className="pillar-card"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <ShieldCheckIcon className="pillar-icon" />
              <h3 className="pillar-title">Secure</h3>
              <p className="pillar-subtitle alt-heading-color">
                Your Data Stays Yours
              </p>
              <p>
                Your data never leaves BooktAI, and we never train models on
                your information. Complete privacy and security you can trust.
              </p>
            </motion.div>

            <motion.div
              className="pillar-card"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <CurrencyDollarIcon className="pillar-icon" />
              <h3 className="pillar-title">Affordable</h3>
              <p className="pillar-subtitle alt-heading-color">
                AI for Everyone
              </p>
              <p>
                Advanced AI tools at consumer-friendly prices. Transparent,
                affordable subscriptions with no surprises or hidden costs.
              </p>
            </motion.div>
          </div>
        </section>

        {/* Feature Highlights Section */}
        <section className="feature-highlights">
          <h2>
            Features You'll Actually{" "}
            <span className="alt-heading-color">Use</span>
          </h2>
          <p className="subtitle">
            BooktAI continuously selects and uses the best AI models
            globally—automatically. You get the most advanced AI without
            worrying about updates, versions, or complexity.
          </p>

          <div className="features-list">
            <motion.div
              className="feature-card"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <DocumentTextIcon className="feature-icon" />
              <h3 className="feature-title">Deep Research</h3>
              <p>
                Get accurate, reliable insights instantly. No endless searches
                or manual digging required.
              </p>
            </motion.div>

            <motion.div
              className="feature-card"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <DocumentPlusIcon className="feature-icon" />
              <h3 className="feature-title">Document & Image Uploads</h3>
              <p>
                Effortlessly upload your documents, images, or files directly
                into your AI conversations. Let BooktAI read, summarize, and
                answer questions based on your own files.
              </p>
            </motion.div>

            <motion.div
              className="feature-card"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <ArrowPathIcon className="feature-icon" />
              <h3 className="feature-title">Superior Memory</h3>
              <p>
                BooktAI remembers context across chats, ensuring your
                interactions always feel personal, relevant, and helpful.
              </p>
            </motion.div>

            <motion.div
              className="feature-card"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <PhotoIcon className="feature-icon" />
              <h3 className="feature-title">Instant Image Generation</h3>
              <p>
                Create high-quality, professional visuals instantly—perfect for
                presentations, social media, and creative projects.
              </p>
            </motion.div>

            <motion.div
              className="feature-card"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <GlobeAltIcon className="feature-icon" />
              <h3 className="feature-title">Real-Time Information</h3>
              <p>
                Always connected, BooktAI automatically uses real-time internet
                data. Your AI assistant never goes out of date, keeping answers
                reliable and current.
              </p>
            </motion.div>

            <motion.div
              className="feature-card"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <UserGroupIcon className="feature-icon" />
              <h3 className="feature-title">Team Collaboration</h3>
              <p>
                Businesses can upload internal documents—like policies, manuals,
                or guidelines. BooktAI makes them interactive for the entire
                team, turning static documents into live, chat-based knowledge
                bases.
              </p>
            </motion.div>
          </div>
        </section>

        {/* Use Cases Section */}
        {/* <section className="use-cases">
          <h2>
            Use BooktAI <span className="alt-heading-color">To</span>
          </h2>

          <div className="use-cases-grid">
            <motion.div
              className="use-case-card"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <ChartBarIcon className="use-case-icon" />
              <h3 className="use-case-title">Boost Productivity</h3>
              <p>
                Create detailed reports, presentations, and visuals
                effortlessly.
              </p>
            </motion.div>

            <motion.div
              className="use-case-card"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <DocumentTextIcon className="use-case-icon" />
              <h3 className="use-case-title">Make Better Decisions</h3>
              <p>
                Instantly reference personal or company documents to inform
                decisions accurately.
              </p>
            </motion.div>

            <motion.div
              className="use-case-card"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <UserGroupIcon className="use-case-icon" />
              <h3 className="use-case-title">Train & Onboard Quickly</h3>
              <p>
                New employees easily interact with policies, procedures, and
                training materials.
              </p>
            </motion.div>

            <motion.div
              className="use-case-card"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <LightBulbIcon className="use-case-icon" />
              <h3 className="use-case-title">Enhance Creativity</h3>
              <p>
                Generate visual content quickly without any design experience.
              </p>
            </motion.div>
          </div>
        </section> */}

        {/* Why Now Section */}
        <section className="why-now">
          <h2>
            Why <span className="alt-heading-color">Now</span>?
          </h2>
          <p className="subtitle">
            AI isn't optional anymore—it's essential. Businesses and individuals
            using AI today will lead tomorrow. BooktAI removes complexity,
            helping you start easily and stay ahead.
          </p>

          <div className="features-grid">
            <motion.div
              className="feature-card"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <h3 className="stat">50%</h3>
              <p>increase in leads with AI</p>
            </motion.div>

            <motion.div
              className="feature-card"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <h3 className="stat">58%</h3>
              <p>faster content creation with AI</p>
            </motion.div>

            <motion.div
              className="feature-card"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <h3 className="stat">81%</h3>
              <p>performance boost with AI</p>
            </motion.div>
          </div>

          {/* <div className="trusted-by">
            <h3>Trusted by Industry Leaders</h3>
            <div className="company-logos-desktop">
              <div className="company-logos">
                <img src="/images/companies/absa-logo.png" alt="ABSA" />
                <img src="/images/companies/hc-logo.png" alt="Harper Collins" />
                <img src="/images/companies/om-logo.png" alt="Old Mutual" />
                <img
                  src="/images/companies/prh-logo.png"
                  alt="Penguin Random House"
                />
                <img src="/images/companies/dotsure-logo.png" alt="Dotsure" />
                <img src="/images/companies/miway-logo.png" alt="MiWay" />
              </div>
            </div>
            {isMobile && (
              <div className="company-logos-mobile">
                <Swiper
                  modules={[Navigation, Autoplay]}
                  spaceBetween={50}
                  slidesPerView={1}
                  autoplay={{ delay: 1500, disableOnInteraction: false }}
                  loop={true}
                  className="company-logos-carousel"
                  centeredSlides={true}
                >
                  <SwiperSlide>
                    <img src="/images/companies/absa-logo.png" alt="ABSA" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/images/companies/hc-logo.png"
                      alt="Harper Collins"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/images/companies/om-logo.png" alt="Old Mutual" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/images/companies/prh-logo.png"
                      alt="Penguin Random House"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/images/companies/dotsure-logo.png"
                      alt="Dotsure"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/images/companies/miway-logo.png" alt="MiWay" />
                  </SwiperSlide>
                </Swiper>
              </div>
            )}
          </div> */}
        </section>

        {/* Testimonials Section */}
        <section className="testimonials">
          <h2>
            What Our Users Are <span className="alt-heading-color">Saying</span>
          </h2>
          <p className="subtitle">Real experiences from BooktAI users.</p>
          <div className="testimonials-desktop">
            <div className="testimonials-grid">
              {testimonials.map((testimonial, index) => (
                <motion.div
                  key={index}
                  className="testimonial-card"
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <div className="testimonial-stars">
                    {[...Array(testimonial.stars)].map((_, i) => (
                      <SolidStarIcon key={i} className="h-5 w-5" />
                    ))}
                  </div>
                  <p className="testimonial-text">"{testimonial.text}"</p>
                  <div className="testimonial-author">
                    <h4>{testimonial.author}</h4>
                    <p>{testimonial.role}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
          {isMobile && (
            <div className="testimonials-mobile">
              <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={24}
                slidesPerView={1}
                navigation={true}
                pagination={{ clickable: true }}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                className="testimonials-carousel"
              >
                {testimonials.map((testimonial, index) => (
                  <SwiperSlide key={index}>
                    <motion.div
                      className="testimonial-card"
                      initial={{ opacity: 0, y: 30 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: true }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: "spring", stiffness: 300 }}
                    >
                      <div className="testimonial-stars">
                        {[...Array(testimonial.stars)].map((_, i) => (
                          <SolidStarIcon key={i} className="h-5 w-5" />
                        ))}
                      </div>
                      <p className="testimonial-text">"{testimonial.text}"</p>
                      <div className="testimonial-author">
                        <h4>{testimonial.author}</h4>
                        <p>{testimonial.role}</p>
                      </div>
                    </motion.div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
          <p className="trusted-text">
            Trusted by thousands, now accessible to you.
          </p>
        </section>

        {/* Pricing Section */}
        <section id="pricing" className="pricing-section">
          <h2>
            Pricing That Makes <span className="alt-heading-color">Sense</span>
          </h2>
          <p className="subtitle">
            Clear, straightforward plans with immediate value.
          </p>

          <div className="billing-toggle">
            <span className={!isAnnual ? "active" : ""}>
              <span className="mobile-text">Monthly</span>
              <span className="desktop-text">Monthly Billing</span>
            </span>
            <button
              className={`toggle-switch ${isAnnual ? "active" : ""}`}
              onClick={() => setIsAnnual(!isAnnual)}
              aria-label="Toggle billing period"
            >
              <span className="toggle-slider" />
            </button>
            <span className={isAnnual ? "active" : ""}>
              <span className="mobile-text">Annual</span>
              <span className="desktop-text">Annual Billing</span>
              <span className="save-badge">Save 20%</span>
            </span>
          </div>

          <p className="guarantee-text pricing-guarantee">
            <strong>
              Try with confidence—7-day money-back guarantee, no questions
              asked.
            </strong>
          </p>

          <div className="pricing-grid">
            <div className="pricing-card starter">
              <div className="pricing-header">
                <h3>Individuals</h3>
                <div className="price">
                  <span className="currency">ZAR</span>
                  <span className="amount">{isAnnual ? "144" : "180"}</span>
                  <span className="period">per user / month</span>
                </div>
                {isAnnual && (
                  <div className="annual-price">
                    ZAR 1728 per user, billed annually
                  </div>
                )}
              </div>
              <div className="pricing-features">
                <ul>
                  <li>Core AI tools</li>
                  <li>Document & image uploads</li>
                  <li>Deep research</li>
                  <li>Image generation</li>
                  <li>Weekly AI insights newsletter</li>
                </ul>
              </div>
              <a
                href="https://my.bookt.app/auth/signup?ai=1"
                className="pricing-cta"
              >
                Subscribe Now
              </a>
            </div>

            <div className="pricing-card business">
              <div className="pricing-header">
                <h3>Businesses</h3>
                <div className="price">
                  <span className="currency">ZAR</span>
                  <span className="amount">{isAnnual ? "216" : "270"}</span>
                  <span className="period">per user / month</span>
                </div>
                {isAnnual && (
                  <div className="annual-price">
                    ZAR 2592 per user, billed annually
                  </div>
                )}
              </div>
              <div className="pricing-features">
                <ul>
                  <li>Everything in Individual, plus:</li>
                  <li>Team document collaboration</li>
                  <li>Priority support</li>
                  <li>Team management tools</li>
                  <li>Business-focused weekly newsletter</li>
                </ul>
              </div>
              <a
                href="https://my.bookt.app/auth/signup?team=1"
                className="pricing-cta"
              >
                Subscribe Now
              </a>
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section className="faq-section">
          <h2>
            Frequently Asked{" "}
            <span className="alt-heading-color">Questions</span>
          </h2>
          <div className="faq-container">
            <motion.div className="faq-item">
              <motion.button
                className="faq-question"
                onClick={() => setExpandedFaq(expandedFaq === 0 ? null : 0)}
                initial={false}
              >
                <span>
                  How does Bookt AI handle my company's sensitive data?
                </span>
                <motion.div
                  className="faq-icon"
                  animate={{ rotate: expandedFaq === 0 ? 180 : 0 }}
                >
                  <ChevronDownIcon className="h-5 w-5" />
                </motion.div>
              </motion.button>
              <motion.div
                className="faq-answer"
                initial={false}
                animate={{
                  height: expandedFaq === 0 ? "auto" : 0,
                  opacity: expandedFaq === 0 ? 1 : 0,
                  marginTop: expandedFaq === 0 ? "1rem" : 0,
                }}
                transition={{ duration: 0.3 }}
              >
                <p>
                  Your data security is our top priority. Bookt AI uses advanced
                  DRM protocols and secure hosting to ensure your company's
                  sensitive information remains private. We never share, sell,
                  or use your data to train our models, and everything stays
                  within your control at all times.
                </p>
              </motion.div>
            </motion.div>

            {/* <motion.div className="faq-item">
              <motion.button
                className="faq-question"
                onClick={() => setExpandedFaq(expandedFaq === 1 ? null : 1)}
                initial={false}
              >
                <span>
                  Do I need technical expertise to implement Bookt AI?
                </span>
                <motion.div
                  className="faq-icon"
                  animate={{ rotate: expandedFaq === 1 ? 180 : 0 }}
                >
                  <ChevronDownIcon className="h-5 w-5" />
                </motion.div>
              </motion.button>
              <motion.div
                className="faq-answer"
                initial={false}
                animate={{
                  height: expandedFaq === 1 ? "auto" : 0,
                  opacity: expandedFaq === 1 ? 1 : 0,
                  marginTop: expandedFaq === 1 ? "1rem" : 0,
                }}
                transition={{ duration: 0.3 }}
              >
                <p>
                  Not at all! Bookt AI is designed to be accessible to everyone,
                  regardless of technical background. Our interface is intuitive
                  and requires no coding or AI expertise. You can be up and
                  running within minutes, with full access to advanced AI
                  capabilities without the complexity.
                </p>
              </motion.div>
            </motion.div> */}

            <motion.div className="faq-item">
              <motion.button
                className="faq-question"
                onClick={() => setExpandedFaq(expandedFaq === 2 ? null : 2)}
                initial={false}
              >
                <span>How does document integration work with Bookt?</span>
                <motion.div
                  className="faq-icon"
                  animate={{ rotate: expandedFaq === 2 ? 180 : 0 }}
                >
                  <ChevronDownIcon className="h-5 w-5" />
                </motion.div>
              </motion.button>
              <motion.div
                className="faq-answer"
                initial={false}
                animate={{
                  height: expandedFaq === 2 ? "auto" : 0,
                  opacity: expandedFaq === 2 ? 1 : 0,
                  marginTop: expandedFaq === 2 ? "1rem" : 0,
                }}
                transition={{ duration: 0.3 }}
              >
                <p>
                  Simply upload your documents (PDFs, Word files, spreadsheets,
                  etc.) to the platform, and BooktAI automatically processes
                  them. During conversations with the AI, it will reference
                  these documents when relevant, providing insights based on
                  your company's specific information. This creates a
                  personalized AI experience that understands your business
                  context.
                </p>
              </motion.div>
            </motion.div>

            {/* <motion.div className="faq-item">
              <motion.button
                className="faq-question"
                onClick={() => setExpandedFaq(expandedFaq === 3 ? null : 3)}
                initial={false}
              >
                <span>Can Bookt AI integrate with our existing software?</span>
                <motion.div
                  className="faq-icon"
                  animate={{ rotate: expandedFaq === 3 ? 180 : 0 }}
                >
                  <ChevronDownIcon className="h-5 w-5" />
                </motion.div>
              </motion.button>
              <motion.div
                className="faq-answer"
                initial={false}
                animate={{
                  height: expandedFaq === 3 ? "auto" : 0,
                  opacity: expandedFaq === 3 ? 1 : 0,
                  marginTop: expandedFaq === 3 ? "1rem" : 0,
                }}
                transition={{ duration: 0.3 }}
              >
                <p>
                  Yes! Our Professional and Enterprise plans offer various
                  integration options with common business tools and software.
                  For Enterprise customers, we also provide custom integrations
                  tailored to your specific tech stack. Please contact our sales
                  team for detailed information about integration possibilities.
                </p>
              </motion.div>
            </motion.div> */}

            <motion.div className="faq-item">
              <motion.button
                className="faq-question"
                onClick={() => setExpandedFaq(expandedFaq === 4 ? null : 4)}
                initial={false}
              >
                <span>How often are the AI models updated?</span>
                <motion.div
                  className="faq-icon"
                  animate={{ rotate: expandedFaq === 4 ? 180 : 0 }}
                >
                  <ChevronDownIcon className="h-5 w-5" />
                </motion.div>
              </motion.button>
              <motion.div
                className="faq-answer"
                initial={false}
                animate={{
                  height: expandedFaq === 4 ? "auto" : 0,
                  opacity: expandedFaq === 4 ? 1 : 0,
                  marginTop: expandedFaq === 4 ? "1rem" : 0,
                }}
                transition={{ duration: 0.3 }}
              >
                <p>
                  Bookt AI continuously evaluates and integrates the latest AI
                  advancements. We regularly update our models to ensure you
                  always have access to cutting-edge capabilities. These updates
                  happen automatically in the background, so you'll always be
                  using the best available technology without any manual updates
                  or disruptions to your workflow.
                </p>
              </motion.div>
            </motion.div>
          </div>
        </section>

        {/* Final CTA Section
        <section className="final-cta">
          <div className="cta-content">
            <h2>Ready to Simplify Your AI Journey?</h2>
            <p>
              Join Bookt AI and experience the benefits of a secure,
              integrated, and simple AI solution that evolves with your
              business.
            </p>
            <a href="#pricing" className="btn-primary">
              Start Your Free Trial
            </a>
          </div>
        </section> */}

        {/* Footer */}
        <footer className="footer">
          <div className="footer-content">
            <h2>Ignite your curiosity</h2>
            <a href="https://my.bookt.app/auth/login" className="footer-cta">
              SIGN IN
            </a>
            <div className="social-links">
              <a
                href="https://x.com/lnkorsten"
                className="social-link"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                </svg>
              </a>
              <a
                href="https://www.youtube.com/@bookt_app"
                className="social-link"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0C.488 3.45.029 5.804 0 12c.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0C23.512 20.55 23.971 18.196 24 12c-.029-6.185-.484-8.549-4.385-8.816zM9 16V8l8 3.993L9 16z" />
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/in/louis-neil/"
                className="social-link"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                </svg>
              </a>
            </div>
            <div className="footer-logo">
              <a href="https://bookt.app/ai">
                <img src="/images/bookt-ai-black-logo.png" alt="Bookt" />
              </a>
            </div>
            <div className="footer-links">
              <a href="https://my.bookt.app/privacy">Privacy Policy</a>
              <a href="https://my.bookt.app/terms">Terms & Conditions</a>
            </div>
            <p className="copyright">
              &copy; {new Date().getFullYear()} — BOOKT
            </p>
          </div>
        </footer>
      </main>
    </div>
  );
}

export default AI;
