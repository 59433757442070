import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { motion, useMotionValue } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, Grid } from "swiper/modules";
import { Helmet } from "react-helmet-async";
import {
  BookOpenIcon,
  DevicePhoneMobileIcon,
  ChatBubbleBottomCenterTextIcon,
  SparklesIcon,
  UserGroupIcon,
  BookmarkIcon,
  StarIcon,
  AcademicCapIcon,
  EyeIcon,
  GlobeAltIcon,
  BookmarkSquareIcon,
  RectangleStackIcon,
  LightBulbIcon,
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
  ClockIcon,
  ArrowsPointingInIcon,
  GlobeAmericasIcon,
  CpuChipIcon,
  DocumentTextIcon,
  PlayCircleIcon,
  MusicalNoteIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import { StarIcon as SolidStarIcon } from "@heroicons/react/24/solid";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/grid";
import "./App.css";
import "./Mastery.css";

// --- Mock Data ---

const appScreenshots = [
  {
    image: "/screenshots/curated-content.png",
    title: "Dynamic Microlearning for Maximum Impact",
    description:
      "Mastery transforms complex training into quick, 10-minute sessions filled with expertly curated, current insights—empowering your team to learn on the go and stay agile.",
  },
  {
    image: "/screenshots/insights.png",
    title: "AI-First Learning Experience",
    description:
      "Our AI isn't just an expert tutor—it's a virtual coworker. It provides real-time insights and practical support for everyday tasks, personalizing learning and accelerating performance.",
  },
  {
    image: "/screenshots/social-learning-image.png",
    title: "Social and Collaborative Learning",
    description:
      "Engage in interactive, peer-driven discussions that transform individual insights into collective success. Mastery builds a vibrant community that fuels continuous innovation.",
  },
];

const testimonials = [
  {
    text: "The daily reading streak reminders are a great touch—although sometimes they arrive a bit too early in the day for me.",
    author: "Alex P.",
    role: "Literature Student",
    stars: 5,
  },
  {
    text: "I joined because a friend recommended it. Still figuring out all the features, but so far I like the clean layout.",
    author: "Stefan B.",
    role: "Book Club Leader",
    stars: 5,
  },
  {
    text: "Love the interactive feel of the app, but it would be great if offline reading was available. I heard it's in the works though!",
    author: "Emily R.",
    role: "Avid Reader",
    stars: 5,
  },
  {
    text: "There's something about this app that keeps me hooked. I love the interactive reading experience and how you can track progress over time. Makes a difference for people like me who want to read more.",
    author: "Tavleen K.",
    role: "Book Enthusiast",
    stars: 5,
  },
  {
    text: "Sharing thoughts on specific sections of the book is a great feature. Feels like I'm part of a book club without any of the pressure. Would love more personalization options in future updates.",
    author: "Matsya B.",
    role: "Book Enthusiast",
    stars: 5,
  },
  {
    text: "Was skeptical at first, but the Companion responses actually add a lot. Makes reading more interactive. Definitely worth a try.",
    author: "Stacie W.",
    role: "Book Enthusiast",
    stars: 5,
  },
  {
    text: "Honestly, Bookt made me enjoy reading again. Didn't think I'd like reading on an app, but it's surprisingly fun.",
    author: "Elena P.",
    role: "Book Enthusiast",
    stars: 5,
  },
  {
    text: "I appreciate how quick the team is with updates. They actually listen to feedback, which is rare with apps these days.",
    author: "Jan M.",
    role: "Book Enthusiast",
    stars: 5,
  },
];

// --- FloatingIcon Component ---

const FloatingIcon = ({
  Icon,
  initialX,
  initialY,
  containerWidth,
  containerHeight,
}) => {
  const x = useMotionValue(initialX);
  const y = useMotionValue(initialY);
  const iconRef = useRef(null);

  useEffect(() => {
    const speed = 0.3;
    const angle = Math.random() * Math.PI * 2;
    const velocity = {
      x: Math.cos(angle) * speed,
      y: Math.sin(angle) * speed,
    };

    const interval = setInterval(() => {
      let newX = x.get() + velocity.x;
      let newY = y.get() + velocity.y;

      // Use container dimensions (fallback to window dimensions if not available)
      const maxWidth = containerWidth || window.innerWidth;
      const maxHeight = containerHeight || window.innerHeight;
      // Subtract icon size (48px) for wrap-around logic
      const boundaryX = maxWidth - 48;
      const boundaryY = maxHeight - 48;

      // Collision detection (simple distance-based bounce)
      if (iconRef.current) {
        const currentRect = iconRef.current.getBoundingClientRect();
        const currentCenter = {
          x: currentRect.left + currentRect.width / 2,
          y: currentRect.top + currentRect.height / 2 + window.scrollY,
        };

        const otherIcons = document.querySelectorAll(".floating-icon");
        otherIcons.forEach((other) => {
          if (other === iconRef.current) return;
          const otherRect = other.getBoundingClientRect();
          const otherCenter = {
            x: otherRect.left + otherRect.width / 2,
            y: otherRect.top + otherRect.height / 2 + window.scrollY,
          };

          const dx = currentCenter.x - otherCenter.x;
          const dy = currentCenter.y - otherCenter.y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < 50) {
            // Collision threshold
            const collisionAngle = Math.atan2(dy, dx);
            velocity.x = Math.cos(collisionAngle) * speed;
            velocity.y = Math.sin(collisionAngle) * speed;
          }
        });
      }

      // Wrap around the container edges
      if (newX < 0) newX = boundaryX;
      if (newX > boundaryX) newX = 0;
      if (newY < 0) newY = boundaryY;
      if (newY > boundaryY) newY = 0;

      x.set(newX);
      y.set(newY);
    }, 16);

    return () => clearInterval(interval);
  }, [containerWidth, containerHeight, x, y]);

  return (
    <motion.div ref={iconRef} className="floating-icon" style={{ x, y }}>
      <Icon />
    </motion.div>
  );
};

// --- FloatingIcons Component ---

const FloatingIcons = () => {
  const [positions, setPositions] = useState([]);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const containerRef = useRef(null);

  useEffect(() => {
    const updatePositions = () => {
      if (!containerRef.current) return;
      const rect = containerRef.current.getBoundingClientRect();
      const width = rect.width;
      const height = rect.height;
      const newPositions = Array.from({ length: 40 }, () => ({
        x: Math.random() * (width - 100), // using a 100px offset as in the original code
        y: Math.random() * height,
      }));
      setPositions(newPositions);
      setContainerSize({ width, height });
    };

    updatePositions();
    window.addEventListener("resize", updatePositions);

    return () => {
      window.removeEventListener("resize", updatePositions);
    };
  }, []);

  const icons = [
    BookOpenIcon,
    RectangleStackIcon,
    SparklesIcon,
    EyeIcon,
    ChatBubbleBottomCenterTextIcon,
    BookmarkIcon,
    GlobeAltIcon,
    AcademicCapIcon,
    LightBulbIcon,
    BookmarkSquareIcon,
  ];

  return (
    <div ref={containerRef} className="floating-icons-container">
      {positions.map((pos, index) => (
        <FloatingIcon
          key={`icon-${index}`}
          Icon={icons[index % icons.length]}
          initialX={pos.x}
          initialY={pos.y}
          containerWidth={containerSize.width}
          containerHeight={containerSize.height}
        />
      ))}
    </div>
  );
};

// --- Main App Component ---

function App() {
  const location = useLocation();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isMobile, setIsMobile] = useState(
    typeof window !== "undefined" && window.innerWidth <= 1024
  );
  const [expandedFaq, setExpandedFaq] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isAnnual, setIsAnnual] = useState(true);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY,
      });
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="app mastery">
      <Helmet>
        <title>
          BOOKT Mastery | AI-Driven Learning for Teams & Individuals
        </title>
        <meta
          name="description"
          content="AI-powered microlearning, real-time insights, and personalized skill-building. Stay ahead, upskill faster, and prepare for the AI-driven future."
        />
        <link rel="canonical" href="https://bookt.app/mastery" />
        <meta
          property="og:title"
          content="BOOKT Mastery | AI-Driven Learning for Teams & Individuals"
        />
        <meta
          property="og:description"
          content="AI-powered microlearning, prepare for the AI-driven future."
        />
        <meta
          property="og:image"
          content="/images/social-preview-mastery.webp"
        />
        <meta property="og:url" content="https://bookt.app/mastery" />
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "Bookt Mastery",
            "url": "https://bookt.app/mastery"
          }
        `}</script>
      </Helmet>
      <nav className={`navbar ${isMobileMenuOpen ? "menu-open" : ""}`}>
        <a href="https://bookt.app">
          <img
            className="mastery-logo"
            src="/images/bookt-mastery-logo.webp"
            alt="Bookt"
          />
        </a>
        <div className="desktop-nav">
          <a
            href={location.pathname === "/mastery" ? "/" : "/mastery"}
            className="nav-cta"
          >
            {location.pathname === "/mastery" ? "Personal" : "Business"}
          </a>
          <a href="https://my.bookt.app/auth/login" className="nav-cta button">
            Login
          </a>
        </div>
        <button
          className="mobile-menu-button"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          {isMobileMenuOpen ? (
            <XMarkIcon className="h-6 w-6" />
          ) : (
            <Bars3Icon className="h-6 w-6" />
          )}
        </button>
        {isMobileMenuOpen && (
          <div className="mobile-menu open">
            <a
              href={location.pathname === "/mastery" ? "/" : "/mastery"}
              className="nav-cta"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              {location.pathname === "/mastery" ? "Personal" : "Business"}
            </a>
            <a
              href="https://my.bookt.app/auth/login"
              className="nav-cta button"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Login
            </a>
          </div>
        )}
      </nav>
      <main>
        {/* <div className="floating-icons-div">
          <FloatingIcons />
        </div> */}
        {/* Hero Section */}
        <section className="hero hero-mastery">
          <div className="hero-container hero-mastery-container">
            <div className="hero-content">
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="hero-title seo-h1"
              >
                Accelerate Workforce Learning with AI-Driven{" "}
                <span className="highlight">Microlearning</span>
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                className="hero-subtitle"
              >
                Empower your team with bite-sized, AI-enhanced learning that
                fits into the flow of your workday
              </motion.p>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
                className="hero-cta"
              >
                <a href="#pricing" className="btn-primary">
                  Get Started
                </a>
                <div className="app-store-buttons">
                  <a
                    href="https://apps.apple.com/us/app/bookt/id6451315434"
                    className="store-button app-store"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/images/app-store/app-store.png"
                      alt="Download on the App Store"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=app.bookt.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    className="store-button play-store"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/images/app-store/play-store.png"
                      alt="Get it on Google Play"
                    />
                  </a>
                </div>
              </motion.div>
            </div>
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, delay: 0.6 }}
              className="hero-laptop"
            >
              <img
                className="hero-laptop-image"
                src="/images/mastery-hero-image.webp"
                alt="BOOKT App Demo"
              />
            </motion.div>
          </div>
        </section>

        {/* Dual Focus Section */}
        <section className="dual-focus">
          <h2>
            Empower Your Team to{" "}
            <span className="alt-heading-color">Learn</span> and{" "}
            <span className="alt-heading-color">Execute</span>
          </h2>
          <p className="subtitle">
            Seamlessly bridge the gap between learning and execution
          </p>
          <div className="dual-focus-grid">
            <motion.div
              className="dual-focus-card"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              transition={{
                duration: 0.6,
                type: "spring",
                stiffness: 300,
              }}
            >
              <h3>
                <BookOpenIcon className="icon" />
                Learn
              </h3>
              <div className="feature">
                <div className="feature-content">
                  <h4>Curated, Real-Time Insights</h4>
                  <p>
                    Our multi-agent system delivers bite-sized, up-to-date
                    knowledge from top non-fiction books, podcasts, videos,
                    research, and news.
                  </p>
                </div>
              </div>
              <div className="feature">
                <div className="feature-content">
                  <h4>Social, Collaborative Learning</h4>
                  <p>
                    Enable your team to learn together, share insights, and grow
                    collectively in a fast-paced environment.
                  </p>
                </div>
              </div>
            </motion.div>

            <motion.div
              className="dual-focus-card"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <h3>
                <LightBulbIcon className="icon" />
                Execute
              </h3>
              <div className="feature">
                <div className="feature-content">
                  <h4>Your Personal AI Expert</h4>
                  <p>
                    Equip every employee with an AI Agent that streamlines all
                    tasks, so they can focus on high-impact work.
                  </p>
                </div>
              </div>
              <div className="feature">
                <div className="feature-content">
                  <h4>Seamless, Secure Integration</h4>
                  <p>
                    Powered by state-of-the-art, self-hosted AI with expert
                    onboarding and training, ensuring robust data security and
                    easy integration.
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </section>

        {/* Features Section */}
        {/* <section className="features">
          <h2>Problems with Traditional Training</h2>
          <p className="subtitle">
            Common challenges that hold back effective corporate learning
          </p>
          <div className="features-grid">
            <motion.div
              className="feature-card"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <ClockIcon className="feature-icon" />
              <h3>Time Constraints</h3>
              <p>
                Lengthy, generic programs disrupt busy schedules and lack
                immediate relevance.
              </p>
            </motion.div>

            <motion.div
              className="feature-card"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <CpuChipIcon className="feature-icon" />
              <h3>Limited AI</h3>
              <p>
                Conventional methods leave teams unprepared for the digital
                transformation, missing out on automation and efficiency.
              </p>
            </motion.div>

            <motion.div
              className="feature-card"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <GlobeAmericasIcon className="feature-icon" />
              <h3>Isolated Learning</h3>
              <p>
                Siloed training fails to foster collaboration, reducing the
                potential for peer-driven growth.
              </p>
            </motion.div>
          </div>
        </section> */}

        {/* How It Works Section */}
        <section className="how-it-works">
          <h2>The Mastery Approach</h2>
          <p className="subtitle">
            Modern solutions for the future of workplace learning
          </p>
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={30}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            autoplay={{ delay: 7000 }}
            className="app-screenshots-carousel"
          >
            {appScreenshots.map((screenshot, index) => (
              <SwiperSlide key={index}>
                <div className="screenshot-content">
                  <h3>{screenshot.title}</h3>
                  <p>{screenshot.description}</p>
                </div>
                <div className="screenshot-image">
                  <img src={screenshot.image} alt={screenshot.title} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </section>

        {/* Comprehensive Features Section */}
        <section className="comprehensive-features">
          <h2>Comprehensive Learning Features</h2>
          <p className="subtitle">
            Everything you need to transform your team's learning experience
          </p>
          <div className="features-grid-container">
            <motion.div
              className="feature-card"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <DocumentTextIcon className="feature-icon" />
              <h3>Curated Learning</h3>
              <p>
                Dynamic key learnings and insights from books, articles, videos
                & more.
              </p>
            </motion.div>

            <motion.div
              className="feature-card"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <PlayCircleIcon className="feature-icon" />
              <h3>Video Insights</h3>
              <p>
                Relevant, context-driven videos integrated into your content,
                providing fresh insights.
              </p>
            </motion.div>

            <motion.div
              className="feature-card"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <MusicalNoteIcon className="feature-icon" />
              <h3>Podcasts</h3>
              <p>
                In-depth discussions at the end of each module, recapping key
                lessons.
              </p>
            </motion.div>

            <motion.div
              className="feature-card"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <CpuChipIcon className="feature-icon" />
              <h3>Expert AI Assistants</h3>
              <p>
                Specialized AI assistants with domain expertise to help your
                team execute ideas.
              </p>
            </motion.div>

            <motion.div
              className="feature-card"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <CurrencyDollarIcon className="feature-icon" />
              <h3>Cashback on Books</h3>
              <p>Earn 15% back on every title you buy outside of Mastery.</p>
            </motion.div>

            <motion.div
              className="feature-card"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <LightBulbIcon className="feature-icon" />
              <h3>Personalized Learning</h3>
              <p>
                AI-driven recommendations tailored to your team's goals and
                progress.
              </p>
            </motion.div>
          </div>
        </section>

        {/* Testimonials Section */}
        {/* <section className="testimonials">
          <h2>What Readers Are Saying</h2>
          <div className="testimonials-desktop">
            <div className="testimonials-grid">
              {testimonials.slice(0, 8).map((testimonial, index) => (
                <motion.div
                  key={index}
                  className="testimonial-card"
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <div className="testimonial-stars">
                    {[...Array(testimonial.stars)].map((_, i) => (
                      <SolidStarIcon key={i} className="h-5 w-5" />
                    ))}
                  </div>
                  <p className="testimonial-text">{testimonial.text}</p>
                  <div className="testimonial-author">
                    <h4>{testimonial.author}</h4>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
          {isMobile && (
            <div className="testimonials-mobile">
              <Swiper
                modules={[Navigation, Autoplay, Grid]}
                spaceBetween={24}
                slidesPerView={1}
                grid={{
                  rows: 2,
                  fill: "row",
                }}
                navigation
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                loop={false}
                className="testimonials-carousel"
              >
                {testimonials.slice(0, 8).map((testimonial, index) => (
                  <SwiperSlide key={index}>
                    <motion.div
                      className="testimonial-card"
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: "spring", stiffness: 300 }}
                    >
                      <div className="testimonial-stars">
                        {[...Array(testimonial.stars)].map((_, i) => (
                          <SolidStarIcon key={i} className="h-5 w-5" />
                        ))}
                      </div>
                      <p className="testimonial-text">{testimonial.text}</p>
                      <div className="testimonial-author">
                        <h4>{testimonial.author}</h4>
                      </div>
                    </motion.div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </section> */}

        {/* Why Now Section */}
        <section className="why-now">
          <h2>Why Now?</h2>
          <p className="subtitle">The future of business is AI-powered</p>

          <div className="features-grid">
            <motion.div
              className="feature-card"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <h3 className="stat">83%</h3>
              <p>
                of companies state that AI is a top priority in their business
                plans
              </p>
            </motion.div>

            <motion.div
              className="feature-card"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <h3 className="stat">20%</h3>
              <p>
                increase in productivity for companies using AI-driven training
                programs
              </p>
            </motion.div>

            <motion.div
              className="feature-card"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <h3 className="stat">67%</h3>
              <p>
                of large enterprises plan to increase their investment in
                AI-driven training
              </p>
            </motion.div>
          </div>

          <div className="trusted-by">
            <h3>Trusted by Industry Leaders</h3>
            <div className="company-logos-desktop">
              <div className="company-logos">
                <img src="/images/companies/absa-logo.png" alt="ABSA" />
                <img src="/images/companies/hc-logo.png" alt="Harper Collins" />
                <img src="/images/companies/om-logo.png" alt="Old Mutual" />
                <img
                  src="/images/companies/prh-logo.png"
                  alt="Penguin Random House"
                />
                <img src="/images/companies/dotsure-logo.png" alt="Dotsure" />
                <img src="/images/companies/miway-logo.png" alt="MiWay" />
              </div>
            </div>
            {isMobile && (
              <div className="company-logos-mobile">
                <Swiper
                  modules={[Navigation, Autoplay]}
                  spaceBetween={50}
                  slidesPerView={1}
                  autoplay={{ delay: 1500, disableOnInteraction: false }}
                  loop={true}
                  className="company-logos-carousel"
                  centeredSlides={true}
                >
                  <SwiperSlide>
                    <img src="/images/companies/absa-logo.png" alt="ABSA" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/images/companies/hc-logo.png"
                      alt="Harper Collins"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/images/companies/om-logo.png" alt="Old Mutual" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/images/companies/prh-logo.png"
                      alt="Penguin Random House"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/images/companies/dotsure-logo.png"
                      alt="Dotsure"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/images/companies/miway-logo.png" alt="MiWay" />
                  </SwiperSlide>
                </Swiper>
              </div>
            )}
          </div>
        </section>

        {/* Pricing Section */}
        <section id="pricing" className="pricing-section">
          <h2>Pricing</h2>
          <p className="subtitle">Choose a plan that works for you</p>

          <div className="billing-toggle">
            <span className={!isAnnual ? "active" : ""}>
              <span className="mobile-text">Monthly</span>
              <span className="desktop-text">Monthly Billing</span>
            </span>
            <button
              className={`toggle-switch ${isAnnual ? "active" : ""}`}
              onClick={() => setIsAnnual(!isAnnual)}
              aria-label="Toggle billing period"
            >
              <span className="toggle-slider" />
            </button>
            <span className={isAnnual ? "active" : ""}>
              <span className="mobile-text">Annual</span>
              <span className="desktop-text">Annual Billing</span>
              <span className="save-badge">Save 33%</span>
            </span>
          </div>

          <div className="pricing-grid">
            <div className="pricing-card starter">
              <div className="pricing-header">
                <h3>Starter</h3>
                <p>Perfect for individuals and small teams</p>
                <div className="price">
                  <span className="currency">$</span>
                  <span className="amount">{isAnnual ? "8" : "12"}</span>
                  <span className="period">per user / month</span>
                </div>
                {isAnnual && (
                  <div className="annual-price">
                    $96 per user, billed annually
                  </div>
                )}
              </div>
              <div className="pricing-features">
                <p>For individuals and teams of 1-40 people</p>
                <ul>
                  <li>Unlimited Mastery Content</li>
                  <li>All Social Features</li>
                  <li>Full AI Access</li>
                  <li>Basic Analytics</li>
                  <li>15% Cashback on Books</li>
                </ul>
              </div>
              <a
                href="mailto:louis@bookt.app?subject=Mastery%20Starter%20Plan%20Inquiry"
                className="pricing-cta"
              >
                Contact Us
              </a>
            </div>

            <div className="pricing-card business">
              <div className="pricing-header">
                <h3>Business</h3>
                <p>For larger organizations with advanced needs</p>
                <div className="price">
                  <span className="amount">Custom pricing</span>
                </div>
              </div>
              <div className="pricing-features">
                <p>For teams of 40+ people</p>
                <ul>
                  <li>Everything in Starter, plus:</li>
                  <li>Bespoke Curated Content</li>
                  <li>Advanced Social Features</li>
                  <li>Advanced Analytics & Reporting</li>
                  <li>24/7 priority support</li>
                  <li>SSO & SAML</li>
                  <li>Dedicated success manager</li>
                </ul>
              </div>
              <a
                href="mailto:louis@bookt.app?subject=Mastery%20Enterprise%20Plan%20Inquiry"
                className="pricing-cta"
              >
                Contact Us
              </a>
            </div>
          </div>
        </section>

        {/* CTA Section */}
        {/* <section className="cta-section">
          <div className="cta-section-div">
            <div className="cta-content">
              <h2 className="cta-title">
                Join readers worldwide and explore immersive books.
              </h2>
              <div className="app-store-buttons">
                <a
                  href="https://apps.apple.com/us/app/bookt/id6451315434"
                  className="store-button app-store"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/images/app-store/app-store.png"
                    alt="Download on the App Store"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=app.bookt.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  className="store-button play-store"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/images/app-store/play-store.png"
                    alt="Get it on Google Play"
                  />
                </a>
              </div>
            </div>
            <div className="cta-image">
              <img src="/images/app-preview.png" alt="BOOKT App Preview" />
            </div>
          </div>
        </section> */}

        {/* FAQ Section */}
        <section className="faq-section">
          <h2>Questions?</h2>
          <div className="faq-container">
            <motion.div className="faq-item">
              <motion.button
                className="faq-question"
                onClick={() => setExpandedFaq(expandedFaq === 0 ? null : 0)}
                initial={false}
              >
                <span>
                  What exactly are dynamic microlearning modules, and how do
                  they fit into a busy workday?
                </span>
                <motion.div
                  className="faq-icon"
                  animate={{ rotate: expandedFaq === 0 ? 180 : 0 }}
                >
                  <ChevronDownIcon className="h-5 w-5" />
                </motion.div>
              </motion.button>
              <motion.div
                className="faq-answer"
                initial={false}
                animate={{
                  height: expandedFaq === 0 ? "auto" : 0,
                  opacity: expandedFaq === 0 ? 1 : 0,
                }}
                transition={{ duration: 0.3 }}
              >
                <p>
                  Our dynamic microlearning modules are curated, AI-powered
                  learning experiences designed to integrate effortlessly into
                  your team's daily routine. Each module represents a week's
                  worth of focused content on a specific topic, with daily
                  10-minute reading steps—available in both text and audio
                  formats—to ensure flexibility on the go.
                </p>
                <p>
                  Our advanced AI agents continuously gather and update
                  relevant, accurate insights from across the internet, so every
                  module delivers the latest trends, practical applications, and
                  contextual depth. This bite-sized approach ensures that even
                  the busiest professionals can continuously upskill without
                  disrupting their workflow.
                </p>
              </motion.div>
            </motion.div>

            <motion.div className="faq-item">
              <motion.button
                className="faq-question"
                onClick={() => setExpandedFaq(expandedFaq === 1 ? null : 1)}
                initial={false}
              >
                <span>
                  How does the embedded AI provide real-time, personalized
                  guidance to employees?
                </span>
                <motion.div
                  className="faq-icon"
                  animate={{ rotate: expandedFaq === 1 ? 180 : 0 }}
                >
                  <ChevronDownIcon className="h-5 w-5" />
                </motion.div>
              </motion.button>
              <motion.div
                className="faq-answer"
                initial={false}
                animate={{
                  height: expandedFaq === 1 ? "auto" : 0,
                  opacity: expandedFaq === 1 ? 1 : 0,
                }}
                transition={{ duration: 0.3 }}
              >
                <p>
                  Our embedded AI is a sophisticated system comprised of
                  multiple specialized agents that work seamlessly behind the
                  scenes. Each module is pre-trained with a deep understanding
                  of its content, and the AI continuously performs real-time
                  internet searches to keep information current.
                </p>
                <p>
                  Beyond tutoring, this AI supports employees in executing
                  critical job tasks—helping to build marketing and sales plans,
                  parse data, create comprehensive reports, and more. By
                  blending robust memory, advanced reasoning, and seamless
                  integration into your daily operations, our AI becomes an
                  indispensable partner in both learning and practical work
                  execution.
                </p>
              </motion.div>
            </motion.div>

            <motion.div className="faq-item">
              <motion.button
                className="faq-question"
                onClick={() => setExpandedFaq(expandedFaq === 2 ? null : 2)}
                initial={false}
              >
                <span>
                  In what ways does Bookt facilitate social and collaborative
                  learning within teams?
                </span>
                <motion.div
                  className="faq-icon"
                  animate={{ rotate: expandedFaq === 2 ? 180 : 0 }}
                >
                  <ChevronDownIcon className="h-5 w-5" />
                </motion.div>
              </motion.button>
              <motion.div
                className="faq-answer"
                initial={false}
                animate={{
                  height: expandedFaq === 2 ? "auto" : 0,
                  opacity: expandedFaq === 2 ? 1 : 0,
                }}
                transition={{ duration: 0.3 }}
              >
                <p>
                  Bookt fosters a dynamic social learning environment by
                  enabling teams to learn together and build on shared insights.
                  As employees progress through modules, they can view and
                  contribute to a collective pool of global and team-specific
                  notes, comments, and annotations.
                </p>
                <p>
                  Interactive features, such as leaderboards, encourage healthy
                  competition and engagement, transforming individual learning
                  into a collaborative, community-driven experience. This shared
                  approach not only deepens understanding but also cultivates a
                  culture of continuous improvement and mutual support within
                  the organization.
                </p>
              </motion.div>
            </motion.div>
          </div>
        </section>

        <footer className="footer">
          <div className="footer-content">
            <h2>Ignite your curiosity</h2>
            <a href="https://my.bookt.app/auth/login" className="footer-cta">
              SIGN IN
            </a>
            <div className="social-links">
              <a
                href="https://x.com/lnkorsten"
                className="social-link"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                </svg>
              </a>
              <a
                href="https://www.youtube.com/@bookt_app"
                className="social-link"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0C.488 3.45.029 5.804 0 12c.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0C23.512 20.55 23.971 18.196 24 12c-.029-6.185-.484-8.549-4.385-8.816zM9 16V8l8 3.993L9 16z" />
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/in/louis-neil/"
                className="social-link"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                </svg>
              </a>
            </div>
            <div className="footer-logo">
              <a href="https://bookt.app">
                <img src="/images/bookt-mastery-logo.png" alt="Bookt" />
              </a>
            </div>
            <div className="footer-links">
              <a href="https://my.bookt.app/privacy">Privacy Policy</a>
              <a href="https://my.bookt.app/terms">Terms & Conditions</a>
            </div>
            <p className="copyright">&copy; 2025 — BOOKT</p>
          </div>
        </footer>
      </main>
    </div>
  );
}

export default App;
